import useSellerThemeValue from 'src/hooks/useSellerTheme';
import { Logo, Container } from './tracking-logo.styles';
import { productTracker } from 'src/product-tracking';
import { FC } from 'react';

const TrackingLogo: FC<{ sellerGroupName: string }> = ({ sellerGroupName }) => {
  const { theme } = useSellerThemeValue();
  const logo = <Logo data-cy="tracking-logo" src={theme.logo} />;
  return (
    <Container $bg={theme.logo_bg}>
      {theme.logo_link ? (
        <a
          href={theme.logo_link}
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            productTracker.openExternalLinkLogo(sellerGroupName);
          }}>
          {logo}
        </a>
      ) : (
        logo
      )}
    </Container>
  );
};

export default TrackingLogo;
