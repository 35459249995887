/* eslint-disable @typescript-eslint/no-non-null-assertion */
import mixpanel from 'mixpanel-browser';

type OpenTrackParams = {
  seller_group_name: string;
  order_type: string;
  carrier_name: string;
  has_eta: boolean;
};

class ProductTracking {
  constructor(projectToken: string) {
    try {
      mixpanel.init(projectToken, { track_pageview: 'url-with-path' });
    } catch (err) {
      // console.error('MIXPANEL:INIT', err);
    }
  }

  track(event: string, params?: Record<string, any>): void {
    try {
      mixpanel.track(event, params);
    } catch (err) {
      console.error('MIXPANEL:TRACK', err);
    }
  }

  openTracking({ seller_group_name, order_type, carrier_name, has_eta }: OpenTrackParams): void {
    this.track('Open Tracking', {
      seller_group_name,
      order_type,
      carrier_name,
      has_eta
    });
  }

  openExternalLinkLogo(seller_group_name: string): void {
    this.track('Open External Link Logo', { seller_group_name });
  }
}

const REACT_APP_MIXPANEL_PROJECT_TOKEN = process.env['REACT_APP_MIXPANEL_PROJECT_TOKEN'];

export const productTracker = new ProductTracking(REACT_APP_MIXPANEL_PROJECT_TOKEN!);
